import React, { useState, useEffect, useContext } from "react";
import { Link, NavLink, useNavigate, useSearchParams } from "react-router-dom";

import "./Catalog.css";
import "react-loading-skeleton/dist/skeleton.css";

import cookies from "js-cookie";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import Pagination from "react-responsive-pagination";

import AliLogo from "../../assets/img/ali-logo.png";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import HeroImg from "../../assets/img/white bg.jpg";

import { axiosInstance } from "../../components/baseUrl";
import { useEventTag } from "../../components/hooks/useEventTag";
import { GlobalContext } from "../../components/utils/GlobalState";
import { useGetAllCategories } from "../../components/hooks/useGetAllCategories";
import { CurrencyExchangeContext } from "../../components/utils/CurrencyExchangeState";
import { useSearchAliexpressProducts } from "../../components/hooks/useSearchAliexpressProducts";
import {
  Capitalize,
  numberWithCommas,
} from "../../components/helpers/functionHelpers";

import ProductSkeleton from "../../components/SkeletonLoader/ProductSkeleton";

const Catalog = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const q = searchParams.get("q");

  const { t } = useTranslation();
  const { user } = useContext(GlobalContext);
  const selectedCurrency = cookies.get("currency") || "NGN";

  const [loading, setLoading] = useState(true);
  const [searchedProduct, setSearchedProduct] = useState([]);

  const { allCategories, cateLoading } = useGetAllCategories();
  const {
    totalCount,
    currentPage,
    handlePageChange,
    isLoading,
    totalPages,
    searchedAliexpressProduct,
    getSearchedAliexpressProducts,
  } = useSearchAliexpressProducts();

  const { handleEventTag } = useEventTag();
  const { convertCurrency } = useContext(CurrencyExchangeContext);

  const ref = React.useRef(null);

  const ref1 = React.useRef(null);

  const handleEventAction = (searchValue) => {
    if (user) {
      handleEventTag(`s_${searchValue}`, user.id);
    } else {
      handleEventTag(`s_${searchValue}`);
    }
  };

  const handleEventCategory = (categoryName) => {
    if (user) {
      handleEventTag(`c_${categoryName}`, user.id);
    } else {
      handleEventTag(`c_${categoryName}`);
    }
  };

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  const scroll2 = (scrollOffset) => {
    ref1.current.scrollLeft += scrollOffset;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const getSearchedProducts = async () => {
    try {
      const { data } = await axiosInstance.get(
        `/product?productStatus=APPROVED&productName=${q}&category=${q}&firstName=${q}`
      );
      setSearchedProduct(data.data.products);
      handleEventAction(q);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleEventTag("e_search");
      console.log(error);
      if (error.message && error.message === "Network Error") {
        navigate("/no-connection");
      }
    }
  };

  useEffect(() => {
    getSearchedProducts();
    getSearchedAliexpressProducts(q);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q, currentPage]);

  return (
    <>
      <Header />
      <section id="product-listing">
        <div className="container">
          <div className="d-flex justify-content-end mb-3">
            <i
              className="fas fa-chevron-left me-4  chev-left carousel-nav"
              onClick={() => scroll(-275)}
            ></i>
            <i
              className="fas fa-chevron-right chev-right carousel-nav"
              onClick={() => scroll(275)}
            ></i>
          </div>
          {cateLoading ? (
            <ul className="product-cat-list" ref={ref}>
              {" "}
              <div className="col-lg-4 col-4">
                <p>
                  <Skeleton height={20} />
                </p>
              </div>
              <div className="col-lg-4 col-4">
                <p>
                  <Skeleton height={20} />
                </p>
              </div>
              <div className="col-lg-4 col-4">
                <p>
                  <Skeleton height={20} />
                </p>
              </div>
            </ul>
          ) : (
            <>
              <ul className="product-cat-list" ref={ref}>
                <NavLink
                  to={`/aliexpress/category/${encodeURIComponent(
                    "NG Hair 7.23"
                  )}`}
                  className={({ isActive }) => {
                    return isActive ? "active-product" : "product-cat";
                  }}
                  onClick={() => handleEventCategory("hairs")}
                >
                  <li>Hairs & Wigs</li>
                </NavLink>
                <NavLink
                  to={`/aliexpress/category/${encodeURIComponent(
                    "DS_HairRemoval&Lights&Tools 10$+"
                  )}`}
                  className={({ isActive }) => {
                    return isActive ? "active-product" : "product-cat";
                  }}
                  onClick={() => handleEventCategory("hairs")}
                >
                  <li>Hair Removal & Tools</li>
                </NavLink>
                <NavLink
                  to={`/aliexpress/category/${encodeURIComponent(
                    "AEB_Clothing_NG"
                  )}`}
                  className={({ isActive }) => {
                    return isActive ? "active-product" : "product-cat";
                  }}
                >
                  <li>Fashions</li>
                </NavLink>
                <NavLink
                  to={`/aliexpress/category/${encodeURIComponent(
                    "NG watches 719"
                  )}`}
                  className={({ isActive }) => {
                    return isActive ? "active-product" : "product-cat";
                  }}
                >
                  <li>Wrist Watches</li>
                </NavLink>
                <NavLink
                  to={`/aliexpress/category/${encodeURIComponent(
                    "NG home appliances"
                  )}`}
                  className={({ isActive }) => {
                    return isActive ? "active-product" : "product-cat";
                  }}
                >
                  <li>Home Appliances</li>
                </NavLink>
                <NavLink
                  to={`/aliexpress/category/${encodeURIComponent(
                    "NG electronic 7.19"
                  )}`}
                  className={({ isActive }) => {
                    return isActive ? "active-product" : "product-cat";
                  }}
                >
                  <li>Electronics</li>
                </NavLink>
                <NavLink
                  to={`/aliexpress/category/${encodeURIComponent(
                    "AEB_Phone&OfficeSupplies_NG"
                  )}`}
                  className={({ isActive }) => {
                    return isActive ? "active-product" : "product-cat";
                  }}
                >
                  <li>Phone & Office Supplies</li>
                </NavLink>
                <NavLink
                  to={`/aliexpress/category/${encodeURIComponent(
                    "DS_Fashions&Cosmetics 20$+"
                  )}`}
                  className={({ isActive }) => {
                    return isActive ? "active-product" : "product-cat";
                  }}
                >
                  <li>Cosmetics</li>
                </NavLink>
                <NavLink
                  to={`/aliexpress/category/${encodeURIComponent(
                    "AEB_BeautySupplies_NG"
                  )}`}
                  className={({ isActive }) => {
                    return isActive ? "active-product" : "product-cat";
                  }}
                >
                  <li>Beauty Supplies</li>
                </NavLink>
                <NavLink
                  to={`/aliexpress/category/${encodeURIComponent(
                    "phones&accessories_ZA topsellers_ 20240423"
                  )}`}
                  className={({ isActive }) => {
                    return isActive ? "active-product" : "product-cat";
                  }}
                >
                  <li>Phones & Accessories</li>
                </NavLink>
                <NavLink
                  to={`/aliexpress/category/${encodeURIComponent(
                    "AEB_ ComputerAccessories_EG"
                  )}`}
                  className={({ isActive }) => {
                    return isActive ? "active-product" : "product-cat";
                  }}
                >
                  <li>Computer & Accessories</li>
                </NavLink>
                <NavLink
                  to={`/aliexpress/category/${encodeURIComponent(
                    "AEB_Home&Kitchen_NG"
                  )}`}
                  className={({ isActive }) => {
                    return isActive ? "active-product" : "product-cat";
                  }}
                  onClick={() => handleEventCategory("hairs")}
                >
                  <li>Home & Kitchen</li>
                </NavLink>
                <NavLink
                  to={`/aliexpress/category/${encodeURIComponent(
                    "USA_beauty&health_topsellers"
                  )}`}
                  className={({ isActive }) => {
                    return isActive ? "active-product" : "product-cat";
                  }}
                  onClick={() => handleEventCategory("hairs")}
                >
                  <li>Health Supplies</li>
                </NavLink>
                <NavLink
                  to={`/aliexpress/category/${encodeURIComponent(
                    "DS_Jewelry&Watch 10$+"
                  )}`}
                  className={({ isActive }) => {
                    return isActive ? "active-product" : "product-cat";
                  }}
                  onClick={() => handleEventCategory("hairs")}
                >
                  <li>Jewelries</li>
                </NavLink>
                <NavLink
                  to={`/aliexpress/category/${encodeURIComponent(
                    "DS_Sports-Clothing&Shoes"
                  )}`}
                  className={({ isActive }) => {
                    return isActive ? "active-product" : "product-cat";
                  }}
                  onClick={() => handleEventCategory("hairs")}
                >
                  <li>Sports</li>
                </NavLink>
                <NavLink
                  to={`/aliexpress/category/${encodeURIComponent(
                    "DS_Automobile&Accessories_bestsellers"
                  )}`}
                  className={({ isActive }) => {
                    return isActive ? "active-product" : "product-cat";
                  }}
                  onClick={() => handleEventCategory("hairs")}
                >
                  <li>Automobile & Accessories</li>
                </NavLink>
                <NavLink
                  to={`/aliexpress/category/${encodeURIComponent(
                    "DS_Automotive&Motorcycle 10$+"
                  )}`}
                  className={({ isActive }) => {
                    return isActive ? "active-product" : "product-cat";
                  }}
                  onClick={() => handleEventCategory("hairs")}
                >
                  <li>Automotive & Motorcycle</li>
                </NavLink>
                <NavLink
                  to={`/aliexpress/category/${encodeURIComponent(
                    "DS_DentalCare_stores"
                  )}`}
                  className={({ isActive }) => {
                    return isActive ? "active-product" : "product-cat";
                  }}
                  onClick={() => handleEventCategory("hairs")}
                >
                  <li>DentalCare Stores</li>
                </NavLink>
                <NavLink
                  to={`/aliexpress/category/${encodeURIComponent(
                    "DS_Mother&Kids 10$+"
                  )}`}
                  className={({ isActive }) => {
                    return isActive ? "active-product" : "product-cat";
                  }}
                  onClick={() => handleEventCategory("hairs")}
                >
                  <li>Mother & Kids</li>
                </NavLink>
                <NavLink
                  to={`/aliexpress/category/${encodeURIComponent(
                    "DS_Medical Devices_limited stores"
                  )}`}
                  className={({ isActive }) => {
                    return isActive ? "active-product" : "product-cat";
                  }}
                  onClick={() => handleEventCategory("hairs")}
                >
                  <li>Others</li>
                </NavLink>
              </ul>
              <ul className="product-cat-list" ref={ref}>
                <NavLink
                  to="/category/all"
                  className={({ isActive }) => {
                    return isActive ? "active-product" : "product-cat";
                  }}
                >
                  <li>{t("All_Categories")}</li>
                </NavLink>
                {allCategories?.map((category) => (
                  <NavLink
                    to={`/category/${category.id}`}
                    className={({ isActive }) => {
                      return isActive ? "active-product" : "product-cat";
                    }}
                    key={category.id}
                  >
                    <li>
                      <span>{category.category}</span>
                    </li>
                  </NavLink>
                ))}
              </ul>
            </>
          )}

          <div>
            {!loading && !isLoading ? (
              <div className="filter-head">
                {searchedProduct?.length > 0 ||
                searchedAliexpressProduct?.length > 0 ? (
                  <h2>
                    {searchedProduct?.length + totalCount} {t("results_found")}
                  </h2>
                ) : (
                  <h2>{t("No_product_matched_your_search")}</h2>
                )}
                <div className="mobile-carousel-nav">
                  <i
                    className="fas fa-chevron-left me-4   carousel-nav"
                    onClick={() => scroll2(-275)}
                  ></i>
                  <i
                    className="fas fa-chevron-right carousel-nav"
                    onClick={() => scroll2(275)}
                  ></i>
                </div>
              </div>
            ) : null}

            {loading || isLoading ? (
              <ProductSkeleton />
            ) : (
              <div className="p-container" ref={ref1}>
                {searchedAliexpressProduct?.map((product) => (
                  <Link
                    to={`/aliexpress/product-info/${product?.productId}`}
                    key={product?.productId}
                    className="product-card"
                  >
                    <div className="product-card-img position__relative">
                      <div className="aliexpress-ctn">
                        <img src={AliLogo} alt="aliexpress logo" />
                      </div>
                      <img
                        src={product?.productMainImageUrl}
                        className=""
                        alt="..."
                      />
                    </div>
                    <div className="product-card-info">
                      <p className="black__friday">BLACK FRIDAY</p>
                      <h3 className="seller-name">
                        {product?.secondLevelCategoryName &&
                          Capitalize(product?.secondLevelCategoryName)}{" "}
                      </h3>

                      <h2 className={"product-name"}>
                        {product?.productTitle &&
                          Capitalize(product?.productTitle)}
                      </h2>

                      <p className="product-price">
                        <span className="p-currency">
                          {product?.targetOriginalPriceCurrency}
                        </span>{" "}
                        {product?.targetSalePrice &&
                          numberWithCommas(product?.targetSalePrice)}{" "}
                      </p>
                      <div className="discount__ctn">
                        <p className="p-currency">
                          {product?.targetOriginalPriceCurrency}
                          {product?.targetOriginalPrice &&
                            numberWithCommas(product?.targetOriginalPrice)}{" "}
                        </p>
                        <p className="discount__per"> -{product?.discount}</p>
                      </div>
                    </div>
                  </Link>
                ))}
                {searchedProduct?.map((product) => (
                  <Link
                    to={`/product-info/${product.id}`}
                    key={product.id}
                    className="product-card "
                  >
                    <div className="product-card-img">
                      <img
                        src={product?.productImages[0]?.image}
                        className=""
                        alt="..."
                      />
                    </div>
                    <div className="product-card-info">
                      <h3 className="seller-name">
                        {product && Capitalize(product.createdBy.firstName)}{" "}
                        {product && Capitalize(product.createdBy.LastName)}
                      </h3>
                      <h2
                        className={
                          product.productName && product.productName.length > 20
                            ? "product-name-long"
                            : "product-name"
                        }
                      >
                        {product && Capitalize(product.productName)}
                      </h2>
                      <p className="product-price">
                        <span className="p-currency">{selectedCurrency}</span>{" "}
                        {product.minPricePerUnit &&
                          numberWithCommas(
                            convertCurrency(
                              product.minPricePerUnit,
                              product?.currencyType,
                              selectedCurrency
                            )
                          )}
                        <span className="p-unit">
                          /{product?.unitForMinOrder}
                        </span>
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            )}

            {totalPages > 1 && (
              <div className="pagination__ctn">
                <Pagination
                  current={currentPage}
                  total={totalPages}
                  onPageChange={handlePageChange}
                  extraClassName="justify-content-start"
                />
              </div>
            )}
          </div>
        </div>
      </section>
      {/* Banner Area */}
      <section className="banner-area">
        <div className="container ">
          <div className="banner-wrap pb-4">
            <img src={HeroImg} className="d-block w-100" alt="..." />
          </div>
        </div>
      </section>
      {/* End of Banner Area */}

      <Footer />
    </>
  );
};

export default Catalog;
