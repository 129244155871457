import React from "react";
import { Link } from "react-router-dom";

import AliLogo from "../../assets/img/ali-logo.png";
import ProductSkeleton from "../SkeletonLoader/ProductSkeleton";

import { numberWithCommas } from "../helpers/functionHelpers";
import { useGetCheapAliexpressProducts } from "../hooks/useGetCheapAliexpressProducts";

const CheapProducts = () => {
  const { isLoading, products } = useGetCheapAliexpressProducts();

  return (
    <>
      {isLoading ? (
        <ProductSkeleton />
      ) : (
        <>
          {products?.length > 0 && (
            <div>
              {/* <div className="section-head">
                <Link to={`/aliexpress/category/${encodedCategory}`}>
                  <h2>{sectionTitle}</h2>
                </Link>
                <div>
                  <i
                    className="fas fa-chevron-left me-4 scroll-icon"
                    onClick={() => scroll(-275)}
                  ></i>
                  <i
                    className="fas fa-chevron-right scroll-icon "
                    onClick={() => scroll(275)}
                  ></i>
                </div>
              </div> */}

              <div className="main-container">
                <div className="p-container">
                  {products?.map((product) => (
                    <Link
                      to={`/aliexpress/product-info/${product?.productId}`}
                      key={product?.productId}
                      className="product-card"
                    >
                      <div className="product-card-img position__relative">
                        <div className="aliexpress-ctn">
                          <img src={AliLogo} alt="aliexpress logo" />
                        </div>
                        <img
                          src={
                            product?.productMainImageUrl &&
                            product?.productMainImageUrl
                          }
                          className=""
                          alt="..."
                        />
                      </div>
                      <div className="product-card-info">
                        <p className="black__friday">BLACK FRIDAY</p>
                        <h3 className="seller-name">
                          {product?.secondLevelCategoryName &&
                            product?.ssecondLevelCategoryName}{" "}
                        </h3>

                        <h2 className={"product-name"}>
                          {product?.productTitle && product?.productTitle}
                        </h2>

                        <p className="product-price">
                          <span className="p-currency">
                            {product?.targetOriginalPriceCurrency}
                          </span>{" "}
                          {product?.targetSalePrice &&
                            numberWithCommas(product?.targetSalePrice)}{" "}
                        </p>
                        <div className="discount__ctn">
                          <p className="p-currency">
                            {product?.targetOriginalPriceCurrency}
                            {product?.targetOriginalPrice &&
                              numberWithCommas(
                                product?.targetOriginalPrice
                              )}{" "}
                          </p>
                          <p className="discount__per"> -{product?.discount}</p>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CheapProducts;
