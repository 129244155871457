import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import cookies from "js-cookie";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";

import SellersHeader from "../SellersComponent/SellersHeader.jsx";
import SellersSidebar from "../SellersComponent/SellerSideBar.jsx";

import { africaCountryData } from "./africaCountries";
import { axiosInstance } from "../../../../components/baseUrl.jsx";
import { GlobalContext } from "../../../../components/utils/GlobalState";
import { BigCard } from "../../../../components/SkeletonLoader/BigCard.js";
import { ProtectedRoutes } from "../../../../components/utils/ProtectedRoutes";
import { toastOptions } from "../../../../components/helpers/functionHelpers.js";
import { useAddProductImage } from "../../../../components/hooks/useAddProductImage.js";
import { useDeleteProductImg } from "../../../../components/hooks/useDeleteProductImg.js";
import { useGetAllCategories } from "../../../../components/hooks/useGetAllCategories.js";
import { CurrencyExchangeContext } from "../../../../components/utils/CurrencyExchangeState.js";

const SellersEditProduct = () => {
  const navigate = useNavigate();
  const selectedCurrency = cookies.get("currency") || "NGN";

  const { t } = useTranslation();
  const { productId } = useParams();
  const { user, userLoading } = useContext(GlobalContext);
  const { allCategories, cateLoading } = useGetAllCategories();
  const { convertCurrency } = useContext(CurrencyExchangeContext);
  const {
    onSelectFile,
    selectedImages,
    handleUploadProductImage,
    setSelectedImages,
  } = useAddProductImage();

  const [price, setPrice] = useState();
  const [country, setCountry] = useState("");
  const [loader, setLoader] = useState(false);
  const [isActiv, setIsActiv] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [rerenderr, setRerenderr] = useState(true);
  const [updateProduct, setUpdateProduct] = useState({});
  const [selectedImage, setSelectedImage] = useState([]);
  const [productSpecific, setProductSpecific] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCurrenci, setSelectedCurrenci] = useState("");
  const { handleDeleteProductImage, rerender } = useDeleteProductImg();

  const currentSelectedCurrency = selectedCurrenci || selectedCurrency;

  const handleCurrencyChange = (e) => {
    setSelectedCurrenci(e.target.value);
    cookies.set("currency", e.target.value);
    setRerenderr((prev) => !prev);
  };

  const handleInput = (index, event) => {
    const values = [...productSpecific];
    values[index][event.target.name] = event.target.value;
    setProductSpecific(values);
  };

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };
  const handleClickk = (id) => {
    setIsActiv(id);
  };

  const handleChange = (e) => {
    setUpdateProduct({
      ...updateProduct,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddFields = () => {
    setProductSpecific([...productSpecific, ["", ""]]);
  };

  const handleRemoveFields = (index) => {
    const myValues = [...productSpecific];
    myValues.splice(index, 1);
    setProductSpecific(myValues);
  };

  const getSpecifications = () => {
    const keys = document.getElementsByClassName("specification-keys");
    const values = document.getElementsByClassName("specification-values");

    const specification = {};
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i].value;
      const value = values[i].value;
      if (key && value) specification[key] = value;
    }
    return specification;
  };

  const getProductInfo = async (e) => {
    try {
      const response = await axiosInstance.get(`/product/${productId}`);
      setUpdateProduct(response?.data?.data);
      const arrayOfCategoryIds = response?.data?.data?.productCategories?.map(
        (obj) => obj.categoryId
      );
      setSelectedCategory(arrayOfCategoryIds);
      setCountry(response?.data?.data?.countryOfOrigin);
      setSelectedImage(response?.data?.data?.productImages);
      const currentPrice = convertCurrency(
        response?.data?.data?.minPricePerUnit,
        response?.data?.data?.currencyType,
        currentSelectedCurrency
      );
      setPrice(currentPrice);
      const responseSpecifications = response.data.data.productSpecification;
      setProductSpecific(Object.entries(responseSpecifications));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.message && error.message === "Network Error") {
        navigate("/no-connection");
      }
    }
  };

  useEffect(() => {
    getProductInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, navigate, rerender, rerenderr]);

  const toggleFormCheck = (id) => {
    if (selectedCategory?.includes(id)) {
      setSelectedCategory(
        selectedCategory?.filter((checkId) => checkId !== id)
      );
    } else {
      setSelectedCategory([...selectedCategory, id]);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (selectedImage.length < 1 && selectedImages.length < 1) {
      toast.error("Please select an image", toastOptions);
    } else if (!updateProduct?.productName) {
      toast.error("Please enter a product name!", toastOptions);
    } else if (!updateProduct.supplyCapacity) {
      toast.error("Please enter a supply capacity!", toastOptions);
    } else if (!updateProduct.unitForSupplyCapacity) {
      toast.error("Please enter a supply capacity unit!", toastOptions);
    } else if (!updateProduct.minOrdersAllowed) {
      toast.error("Please enter a minimum order  allowed!", toastOptions);
    } else if (!updateProduct.unitForMinOrder) {
      toast.error("Please enter a unit for minimum order!", toastOptions);
    } else if (!updateProduct.minDuration) {
      toast.error("Please enter minimum duration!", toastOptions);
    } else if (!updateProduct.maxDuration) {
      toast.error("Please enter maximum duration!", toastOptions);
    } else if (!updateProduct.productDescription) {
      toast.error("Please enter production description!", toastOptions);
    } else if (!country) {
      toast.error("Please enter country!", toastOptions);
    } else if (selectedCategory.length < 1) {
      toast.error("Please select a category", toastOptions);
    } else
      try {
        setLoader(true);
        await axiosInstance.patch(`/product/${productId}`, {
          productName: updateProduct.productName,
          supplyCapacity: updateProduct.supplyCapacity,
          unitForSupplyCapacity: updateProduct.unitForSupplyCapacity,
          unitForMinOrder: updateProduct.unitForMinOrder,
          minDuration: updateProduct.minDuration,
          maxDuration: updateProduct.maxDuration,
          productDescription: updateProduct.productDescription,
          minOrdersAllowed: updateProduct.minOrdersAllowed,
          specification: getSpecifications(),
          countryOfOrigin: country,
          categoryIds: selectedCategory,
          minPricePerUnit: parseFloat(price),
        });
        setLoader(false);
        if (selectedImages.length > 0) {
          handleUploadProductImage(productId);
        }
        toast.success("Product successfully updated.", {
          duration: 4000,
          style: {
            background: "#353434",
            color: "#fff",
          },
        });
        setTimeout(() => {
          navigate(-1);
        }, 5600);
      } catch (error) {
        setLoader(false);
        console.log(error);
        if (!error.response.data.errors) {
          return navigate(`/no-connection`);
        }
        toast.error(`${error.response.data.errors[0].message}`, {
          duration: 4000,
          style: {
            background: "#353434",
            color: "#fff",
          },
        });
      }
  };

  return (
    <>
      <Toaster />
      <div>
        <div className="grid-container">
          <SellersHeader
            user={user}
            userLoading={userLoading}
            handleClick={handleClick}
            title="Edit Product"
          />

          <SellersSidebar isActive={isActive} handleClick={handleClick} />

          {loading ? (
            <BigCard />
          ) : cateLoading ? (
            <BigCard />
          ) : (
            <main className="main">
              <div className="main-overview new-product-container">
                <div>
                  <p className="new-product-container-title">
                    {t("Product_information")}
                  </p>
                  <p className="imp-info-text">{t("To_edit_product")}</p>
                  <div className="overview-card new-product padding-10">
                    <div className="row">
                      <div className="product-row-info  col-md-6 col-sm-10 ">
                        <label>{t("Product_Name")}</label>
                        <p className="imp-info-text">{t("Enter_name")}</p>
                        <input
                          className="form-control"
                          type="text"
                          id="fullname"
                          name="productName"
                          value={updateProduct.productName}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="product-row-info col-lg-6 col-md-8 col-sm-10">
                        <label>{t("Minimum_Order")}</label>
                        <p className="imp-info-text">
                          {t("Enter_the_minimum")}
                        </p>
                        <div className="supplycapacitydiv">
                          <input
                            name="minOrdersAllowed"
                            value={updateProduct.minOrdersAllowed}
                            onChange={handleChange}
                          />
                          <select
                            name="unitForMinOrder"
                            value={updateProduct.unitForMinOrder}
                            onChange={handleChange}
                            aria-label="Default select example"
                            required
                          >
                            <option value="Acre(s)">Acre</option>
                            <option value="Ampere(s)">Ampere</option>
                            <option value="Bag(s)">Bags</option>
                            <option value="Barrel(s)">Barrel</option>
                            <option value="Box(es)">Boxes</option>
                            <option value="Bundle(s)">Bundle</option>
                            <option value="Bushel">Bushel</option>
                            <option value="Carat(s)">Carat</option>
                            <option value="Carton(s)">Carton</option>
                            <option value="Case">Case</option>
                            <option value="Centimeter(s)">Centimeter</option>
                            <option value="Chain">Chain</option>
                            <option value="Container(s)">Container</option>
                            <option value="Cubic Foot(s)">Cubic Foot</option>
                            <option value="Cubic Inch(s)">Cubic Inch</option>
                            <option value="Cubic Meter(s)">Cubic Meter</option>
                            <option value="Cubic Yard(s)">Cubic Yard</option>
                            <option value="Dozen(s)">Dozen</option>
                            <option value="Dram(s)">Dram</option>
                            <option value="Fluid Ounce(s)">Fluid Ounce</option>
                            <option value="Foot">Foot</option>
                            <option value="Gallon(s)">Gallon</option>
                            <option value="Gill(s)">Gill</option>
                            <option value="Grain(s)">Grains</option>
                            <option value="Gram(s)">Gram</option>
                            <option value="Hectare(s)">Hectare</option>
                            <option value="Hertz">Hertz</option>
                            <option value="Inch(s)">Inch</option>
                            <option value="Kiloampere(s)">Kiloampere</option>
                            <option value="Kilogram(s)">Kilogram</option>
                            <option value="Kilohertz">Kilohertz</option>
                            <option value="Kilometer(s)">Kilometer</option>
                            <option value="Kiloohm(s)">Kiloohm</option>
                            <option value="Kilovolt(s)">Kilovolt</option>
                            <option value="Kilowatt(s)">Kilowatt</option>
                            <option value="Liter(s)">Liter</option>
                            <option value="Long Ton(s)">Long Ton</option>
                            <option value="Megahertz">Megahertz</option>
                            <option value="Meter(s)">Meter</option>
                            <option value="Metric Ton(s)">Metric Ton</option>
                            <option value="Mile(s)">Mile</option>
                            <option value="Milliampere(s)">Milliampere</option>
                            <option value="Milligram(s)">Milligram</option>
                            <option value="Millihertz">Millihertz</option>
                            <option value="Milliliter(s)">Milliliter</option>
                            <option value="Millimeter(s)">Millimeter </option>
                            <option value="Milliohm(s)">Milliohm</option>
                            <option value="Millivolt(s)">Millivolt</option>
                            <option value="Milliwatt(s)">Milliwatt</option>
                            <option value="Ohm(s)">Ohm</option>
                            <option value="Ounce(s)">Ounce</option>
                            <option value="Pack(s)">Pack</option>
                            <option value="Pair(s)">Pairs</option>
                            <option value="Pallet(s)">Pallet</option>
                            <option value="Parcel(s)">Parcel</option>
                            <option value="Perch(s)">Perch</option>
                            <option value="Piece(s)">Piece</option>
                            <option value="Pint(s)">Pint</option>
                            <option value="Plant(s)">Plant</option>
                            <option value="Pole(s)">Pole</option>
                            <option value="Pound(s)">Pound</option>
                            <option value="Quart(s)">Quart</option>
                            <option value="Quarter(s)">Quarter</option>
                            <option value="Ream(s)">Reams</option>
                            <option value="Rod(s)">Rod</option>
                            <option value="Roll(s)">Rolls</option>
                            <option value="Set(s)">Sets</option>
                            <option value="Sheet(s)">Sheet</option>
                            <option value="Short Ton(s)">Short Ton</option>
                            <option value="Square Feet(s)">Square Feet</option>
                            <option value="Square Inch(s)">Square Inch</option>
                            <option value="Square Meter(s)">
                              Square Meters
                            </option>
                            <option value="Square Mile(s)">Square Mile</option>
                            <option value="Square Yard(s)">Square Yard</option>
                            <option value="Stone(s)">Stone</option>
                            <option value="Strand(s)">Strand</option>
                            <option value="Tonne(s)">Tonne</option>
                            <option value="Tray(s)">Tray</option>
                            <option value="Unit(s)">Unit</option>
                            <option value="Volt(s)">Volt</option>
                            <option value="Watt(s)">Watt</option>
                            <option value="Wp(s)">Wp</option>
                            <option value="Yard(s)">Yard</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="product-row-info col-lg-6 col-md-8 col-sm-10 ">
                        <label>{t("Country_of_Origin")}</label>
                        <Form.Select
                          size="sm"
                          name="country"
                          value={country}
                          onChange={setCountry}
                        >
                          {Object.entries(africaCountryData).map(
                            (country, index) => (
                              <option key={index} value={`${country[1]}`}>
                                {country[1]}
                              </option>
                            )
                          )}
                        </Form.Select>
                      </div>
                      <div className="product-row-info col-lg-6 col-md-8 col-sm-10">
                        <label>{t("Supply_Capacity")}</label>
                        <div className="supplycapacitydiv ">
                          <input
                            name="supplyCapacity"
                            value={updateProduct.supplyCapacity}
                            onChange={handleChange}
                          />
                          <select
                            name="unitForSupplyCapacity"
                            value={updateProduct.unitForSupplyCapacity}
                            onChange={handleChange}
                            aria-label="Default select example"
                            required
                          >
                            <option value="Acre(s)">Acre</option>
                            <option value="Ampere(s)">Ampere</option>
                            <option value="Bag(s)">Bags</option>
                            <option value="Barrel(s)">Barrel</option>
                            <option value="Box(es)">Boxes</option>
                            <option value="Bundle(s)">Bundle</option>
                            <option value="Bushel">Bushel</option>
                            <option value="Carat(s)">Carat</option>
                            <option value="Carton(s)">Carton</option>
                            <option value="Case">Case</option>
                            <option value="Centimeter(s)">Centimeter</option>
                            <option value="Chain">Chain</option>
                            <option value="Container(s)">Container</option>
                            <option value="Cubic Foot(s)">Cubic Foot</option>
                            <option value="Cubic Inch(s)">Cubic Inch</option>
                            <option value="Cubic Meter(s)">Cubic Meter</option>
                            <option value="Cubic Yard(s)">Cubic Yard</option>
                            <option value="Dozen(s)">Dozen</option>
                            <option value="Dram(s)">Dram</option>
                            <option value="Fluid Ounce(s)">Fluid Ounce</option>
                            <option value="Foot">Foot</option>
                            <option value="Gallon(s)">Gallon</option>
                            <option value="Gill(s)">Gill</option>
                            <option value="Grain(s)">Grains</option>
                            <option value="Gram(s)">Gram</option>
                            <option value="Hectare(s)">Hectare</option>
                            <option value="Hertz">Hertz</option>
                            <option value="Inch(s)">Inch</option>
                            <option value="Kiloampere(s)">Kiloampere</option>
                            <option value="Kilogram(s)">Kilogram</option>
                            <option value="Kilohertz">Kilohertz</option>
                            <option value="Kilometer(s)">Kilometer</option>
                            <option value="Kiloohm(s)">Kiloohm</option>
                            <option value="Kilovolt(s)">Kilovolt</option>
                            <option value="Kilowatt(s)">Kilowatt</option>
                            <option value="Liter(s)">Liter</option>
                            <option value="Long Ton(s)">Long Ton</option>
                            <option value="Megahertz">Megahertz</option>
                            <option value="Meter(s)">Meter</option>
                            <option value="Metric Ton(s)">Metric Ton</option>
                            <option value="Mile(s)">Mile</option>
                            <option value="Milliampere(s)">Milliampere</option>
                            <option value="Milligram(s)">Milligram</option>
                            <option value="Millihertz">Millihertz</option>
                            <option value="Milliliter(s)">Milliliter</option>
                            <option value="Millimeter(s)">Millimeter </option>
                            <option value="Milliohm(s)">Milliohm</option>
                            <option value="Millivolt(s)">Millivolt</option>
                            <option value="Milliwatt(s)">Milliwatt</option>
                            <option value="Ohm(s)">Ohm</option>
                            <option value="Ounce(s)">Ounce</option>
                            <option value="Pack(s)">Pack</option>
                            <option value="Pair(s)">Pairs</option>
                            <option value="Pallet(s)">Pallet</option>
                            <option value="Parcel(s)">Parcel</option>
                            <option value="Perch(s)">Perch</option>
                            <option value="Piece(s)">Piece</option>
                            <option value="Pint(s)">Pint</option>
                            <option value="Plant(s)">Plant</option>
                            <option value="Pole(s)">Pole</option>
                            <option value="Pound(s)">Pound</option>
                            <option value="Quart(s)">Quart</option>
                            <option value="Quarter(s)">Quarter</option>
                            <option value="Ream(s)">Reams</option>
                            <option value="Rod(s)">Rod</option>
                            <option value="Roll(s)">Rolls</option>
                            <option value="Set(s)">Sets</option>
                            <option value="Sheet(s)">Sheet</option>
                            <option value="Short Ton(s)">Short Ton</option>
                            <option value="Square Feet(s)">Square Feet</option>
                            <option value="Square Inch(s)">Square Inch</option>
                            <option value="Square Meter(s)">
                              Square Meters
                            </option>
                            <option value="Square Mile(s)">Square Mile</option>
                            <option value="Square Yard(s)">Square Yard</option>
                            <option value="Stone(s)">Stone</option>
                            <option value="Strand(s)">Strand</option>
                            <option value="Tonne(s)">Tonne</option>
                            <option value="Tray(s)">Tray</option>
                            <option value="Unit(s)">Unit</option>
                            <option value="Volt(s)">Volt</option>
                            <option value="Watt(s)">Watt</option>
                            <option value="Wp(s)">Wp</option>
                            <option value="Yard(s)">Yard</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="product-row-info col-md-6 col-sm-10">
                        <label>{t("Min_delivery_duration")}</label>
                        <input
                          className="form-control"
                          type="text"
                          id="lead time max"
                          name="minDuration"
                          value={updateProduct.minDuration}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="product-row-info col-md-6 col-sm-10">
                        <label>{t("Max_delivery_duration")}</label>
                        <input
                          className="form-control"
                          type="text"
                          id="lead time min"
                          name="maxDuration"
                          value={updateProduct.maxDuration}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="product-row-info col-lg-6">
                        <label>{t("Product_Specification")}</label>
                        <p className="imp-info-text">
                          {t(
                            "Click on the plus or minus sign to add new types / values"
                          )}
                        </p>
                        {productSpecific.map((specification, index) => (
                          <div className="row mb-4" key={index}>
                            <div className="col-sm-6 col-10 mb-2">
                              <input
                                className="form-control specification-keys"
                                defaultValue={specification[0]}
                                onChange={(event) => handleInput(index, event)}
                                type="text"
                                id="type"
                                placeholder="product type"
                              />
                            </div>

                            <div className="col-sm-6 col-10 mb-2">
                              <div className="productspecification-value">
                                <input
                                  className="form-control specification-values"
                                  type="text"
                                  id="type"
                                  defaultValue={specification[1]}
                                  onChange={(event) =>
                                    handleInput(index, event)
                                  }
                                  placeholder="value"
                                />
                                <div>
                                  <button>
                                    <i
                                      className="fas fa-plus"
                                      onClick={() => handleAddFields()}
                                    ></i>
                                  </button>
                                  <button>
                                    <i
                                      className="fas fa-minus"
                                      onClick={() => handleRemoveFields()}
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="product-row-info  col-md-6 col-sm-10 ">
                        <label>
                          {t("Price")} ({currentSelectedCurrency}/{" "}
                          {updateProduct.unitForMinOrder
                            ? updateProduct.unitForMinOrder
                            : "Unit"}
                          )
                        </label>
                        <p className="imp-info-text">
                          {t("Enter_the_minimum")}
                        </p>
                        <div className="supplycapacitydiv ">
                          <input
                            id="number"
                            className="form-control"
                            type="text"
                            name="price"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                          />
                          <select
                            name="selectedCurrenci"
                            value={selectedCurrenci}
                            onChange={handleCurrencyChange}
                          >
                            <option defaultValue={currentSelectedCurrency}>
                              {currentSelectedCurrency}
                            </option>
                            {currentSelectedCurrency !== "USD" && (
                              <option value="USD">USD</option>
                            )}
                            {currentSelectedCurrency !== "NGN" && (
                              <option value="NGN">NGN</option>
                            )}
                            {currentSelectedCurrency !== "EUR" && (
                              <option value="EUR">EUR</option>
                            )}
                            {currentSelectedCurrency !== "GBP" && (
                              <option value="GBP">GBP</option>
                            )}
                            {currentSelectedCurrency !== "XOF" && (
                              <option value="XOF">XOF</option>
                            )}
                            {currentSelectedCurrency !== "GHS" && (
                              <option value="GHS">GHS</option>
                            )}
                          </select>
                        </div>
                      </div>
                    </div>

                    <label>{t("Product_Description")}</label>
                    <div className="product-info-description pro-des">
                      <textarea
                        className="form-control"
                        type="text"
                        id="text"
                        rows="4"
                        name="productDescription"
                        value={updateProduct.productDescription}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="categories">
                  <h6>{t("Categories")}</h6>
                  <p className="imp-info-text">{t("Selecting_the_right")}</p>
                  <div className="overview-card categorieslist-container padding-10">
                    {allCategories?.map((category, index) => (
                      <div key={index}>
                        <div
                          className="categorieslist"
                          onClick={() => handleClickk(category.id)}
                        >
                          <label
                            htmlFor={category.category}
                            className=" categoriesdescription"
                          >
                            <img src={category.icon} alt="" />
                            <p>{category.category}</p>
                          </label>

                          <Form.Check
                            checked={selectedCategory?.includes(category.id)}
                            aria-label={index}
                            value={category?.id}
                            onChange={() => toggleFormCheck(category.id)}
                            id={category?.category}
                          />
                        </div>
                        <div
                          className={
                            category.id === isActiv ? " showw" : "hidee"
                          }
                        >
                          {category?.children &&
                            category?.children?.length > 0 &&
                            category?.children?.map(
                              (layerOneCategory, index) => (
                                <div key={layerOneCategory.id}>
                                  <div className="categorieslist">
                                    <label
                                      htmlFor={layerOneCategory.category}
                                      className="categoriesdescription layer-one-margin"
                                    >
                                      <img src={layerOneCategory.icon} alt="" />
                                      <p>-</p>
                                      <p>{layerOneCategory.category}</p>
                                    </label>
                                    <Form.Check
                                      checked={selectedCategory?.includes(
                                        layerOneCategory.id
                                      )}
                                      aria-label={index}
                                      value={layerOneCategory.id}
                                      onChange={() =>
                                        toggleFormCheck(layerOneCategory.id)
                                      }
                                      id={layerOneCategory.category}
                                    />
                                  </div>
                                  <div>
                                    {layerOneCategory?.children &&
                                      layerOneCategory?.children?.length > 0 &&
                                      layerOneCategory?.children?.map(
                                        (layerTwoCategory, index) => (
                                          <div key={layerTwoCategory.id}>
                                            <div className="categorieslist">
                                              <label
                                                htmlFor={
                                                  layerTwoCategory.category
                                                }
                                                className="categoriesdescription layer-two-margin"
                                              >
                                                <img
                                                  src={layerTwoCategory.icon}
                                                  alt=""
                                                />
                                                <p>- -</p>
                                                <p>
                                                  {layerTwoCategory.category}
                                                </p>
                                              </label>
                                              <Form.Check
                                                checked={selectedCategory?.includes(
                                                  layerTwoCategory.id
                                                )}
                                                aria-label={index}
                                                value={layerTwoCategory.id}
                                                onChange={() =>
                                                  toggleFormCheck(
                                                    layerTwoCategory.id
                                                  )
                                                }
                                                id={layerTwoCategory.category}
                                              />
                                            </div>
                                            <div>
                                              {layerTwoCategory.children &&
                                                layerTwoCategory.children
                                                  .length > 0 &&
                                                layerTwoCategory.children.map(
                                                  (
                                                    layerThreeCategory,
                                                    index
                                                  ) => (
                                                    <div
                                                      key={
                                                        layerThreeCategory.id
                                                      }
                                                    >
                                                      <div className="categorieslist">
                                                        <label
                                                          htmlFor={
                                                            layerThreeCategory.category
                                                          }
                                                          className="categoriesdescription layer-three-margin"
                                                        >
                                                          <img
                                                            src={
                                                              layerThreeCategory.icon
                                                            }
                                                            alt=""
                                                          />
                                                          <p>- - -</p>
                                                          <p>
                                                            {
                                                              layerThreeCategory.category
                                                            }
                                                          </p>
                                                        </label>
                                                        <Form.Check
                                                          checked={selectedCategory?.includes(
                                                            layerThreeCategory.id
                                                          )}
                                                          aria-label={index}
                                                          value={
                                                            layerThreeCategory.id
                                                          }
                                                          onChange={() =>
                                                            toggleFormCheck(
                                                              layerThreeCategory.id
                                                            )
                                                          }
                                                          id={
                                                            layerThreeCategory.category
                                                          }
                                                        />
                                                      </div>
                                                      <div>
                                                        {layerThreeCategory.children &&
                                                          layerThreeCategory
                                                            .children.length >
                                                            0 &&
                                                          layerThreeCategory.children.map(
                                                            (
                                                              layerFourCategory,
                                                              index
                                                            ) => (
                                                              <div
                                                                key={
                                                                  layerFourCategory.id
                                                                }
                                                              >
                                                                <div className="categorieslist">
                                                                  <label
                                                                    htmlFor={
                                                                      layerFourCategory.category
                                                                    }
                                                                    className="categoriesdescription layer-four-margin"
                                                                  >
                                                                    <img
                                                                      src={
                                                                        layerFourCategory.icon
                                                                      }
                                                                      alt=""
                                                                    />
                                                                    <p>
                                                                      - - - -
                                                                    </p>
                                                                    <p>
                                                                      {
                                                                        layerFourCategory.category
                                                                      }
                                                                    </p>
                                                                  </label>
                                                                  <Form.Check
                                                                    checked={selectedCategory?.includes(
                                                                      layerFourCategory.id
                                                                    )}
                                                                    aria-label={
                                                                      index
                                                                    }
                                                                    value={
                                                                      layerFourCategory.id
                                                                    }
                                                                    onChange={() =>
                                                                      toggleFormCheck(
                                                                        layerFourCategory.id
                                                                      )
                                                                    }
                                                                    id={
                                                                      layerFourCategory.category
                                                                    }
                                                                  />
                                                                </div>
                                                                <div>
                                                                  {layerFourCategory.children &&
                                                                    layerFourCategory
                                                                      .children
                                                                      .length >
                                                                      0 &&
                                                                    layerFourCategory.children.map(
                                                                      (
                                                                        layerFiveCategory,
                                                                        index
                                                                      ) => (
                                                                        <div
                                                                          key={
                                                                            layerFiveCategory.id
                                                                          }
                                                                        >
                                                                          <div className="categorieslist">
                                                                            <label
                                                                              htmlFor={
                                                                                layerFiveCategory.category
                                                                              }
                                                                            >
                                                                              <div className="categoriesdescription layer-four-margin">
                                                                                <img
                                                                                  src={
                                                                                    layerFiveCategory.icon
                                                                                  }
                                                                                  alt=""
                                                                                />
                                                                                <p>
                                                                                  -
                                                                                  -
                                                                                  -
                                                                                  -
                                                                                  -
                                                                                </p>
                                                                                <p>
                                                                                  {
                                                                                    layerFiveCategory.category
                                                                                  }
                                                                                </p>
                                                                              </div>
                                                                            </label>

                                                                            <Form.Check
                                                                              checked={selectedCategory?.includes(
                                                                                layerFiveCategory.id
                                                                              )}
                                                                              aria-label={
                                                                                index
                                                                              }
                                                                              value={
                                                                                layerFiveCategory.id
                                                                              }
                                                                              onChange={() =>
                                                                                toggleFormCheck(
                                                                                  layerFiveCategory
                                                                                )
                                                                              }
                                                                              id={
                                                                                layerFiveCategory.category
                                                                              }
                                                                            />
                                                                          </div>
                                                                        </div>
                                                                      )
                                                                    )}
                                                                </div>
                                                              </div>
                                                            )
                                                          )}
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                            </div>
                                          </div>
                                        )
                                      )}
                                  </div>
                                </div>
                              )
                            )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="categories-footer mb-5">
                <div className="featured-image">
                  <h6>{t("Featured_Image")}</h6>
                  <p>{t("You_are_allowed")}</p>
                  <div className="overview-card image-upload padding-10">
                    <label htmlFor="uploadImage">
                      <i className="fas fa-cloud-upload"></i>
                      <h6> {t("Drop_Image_here")}</h6>
                      <p>
                        {t("Or_Browse_Images")} <Link to=""> {t("here")}</Link>{" "}
                      </p>
                    </label>
                    <input
                      type="file"
                      id="uploadImage"
                      name="image"
                      accept="image/*"
                      onChange={onSelectFile}
                      multiple
                    />
                    <div className="d-flex image-container">
                      <>
                        {selectedImage &&
                          selectedImage.map((image, index) => {
                            return (
                              <div key={index} className="send-image">
                                <img
                                  src={image.image}
                                  alt=""
                                  className="image"
                                />

                                <div className="bin-icon">
                                  <i
                                    className="fas fa-trash-alt"
                                    onClick={() =>
                                      handleDeleteProductImage(image.id)
                                    }
                                  ></i>
                                </div>
                              </div>
                            );
                          })}
                      </>
                      <>
                        {selectedImages &&
                          selectedImages.map((image, index) => {
                            return (
                              <div key={index} className="send-image">
                                <img src={image} alt="" className="image" />

                                <div className="bin-icon">
                                  <i
                                    className="fas fa-trash-alt"
                                    onClick={() =>
                                      setSelectedImages(
                                        selectedImages.filter(
                                          (e) => e !== image
                                        )
                                      )
                                    }
                                  ></i>
                                </div>
                              </div>
                            );
                          })}
                      </>
                    </div>
                  </div>
                </div>
              </div>
              <div className="categories-footer-button">
                {!loader ? (
                  <button onClick={handleUpdate}>{t("Update_Changes")}</button>
                ) : (
                  <button>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                )}
              </div>
            </main>
          )}
        </div>
      </div>
    </>
  );
};

export default ProtectedRoutes(SellersEditProduct, ["SELLER"]);
