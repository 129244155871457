import { useEffect, useState } from "react";
import { axiosInstance } from "../baseUrl";

export const useGetCheapAliexpressProducts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const getCheapProducts = async () => {
    setIsLoading(true);
    try {
      const { data } = await axiosInstance.get(`/ali-express/products?page=1`);
      setProducts(data?.products);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCheapProducts();
  }, []);

  return {
    isLoading,
    products,
  };
};
